import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import ContactForm from '../components/contact-form'
import Layout from '../components/layout'
import Seo from '../components/seo'

const ElTaller = () => {
  return (
    <Layout>
      <Seo
        title='Taller de servicios - MECI'
        description='Darle mantenimiento a su equipo contra incendio es tan importante como adquirir uno. Visite nuestro taller para reparaciones menores, recargas y mantenimiento preventivo.'
      />
      <div className='col-12 col-md-10 mx-auto my-5'>
        <h1 className='fw-bold text-center'>
          TALLER DE SERVICIOS DE RECARGAS Y MANTENIMIENTO
        </h1>
        <p className='lead text-center'>
          EXTINTORES, SISTEMAS Y TODO LO RELACIONADO A EQUIPO CONTRA INCENDIOS
        </p>
        <p className='text-center'>
          Lleve sus extintores para mantenimiento y/o recarga acorde a la
          etiqueta, recuerde que es importante tener un control de mantenimiento
          para que su equipo contra incendio sea efectivo. Puede{' '}
          <Link to='#hero-form' className='orange-link'>
            ponerse en contacto
          </Link>{' '}
          para agendar una visita al taller o bien para solicitar un servicio a
          domicilio.
        </p>
      </div>
      <div className='col-12 col-md-10 d-flex flex-wrap justify-content-around mx-auto'>
        <StaticImage
          src='../images/taller/taller-1.jpg'
          alt='Hombre recargando un extintor'
          layout='constrained'
          width='320'
          className='my-3'
        />
        <StaticImage
          src='../images/taller/taller-2.jpg'
          alt='Taller MECI'
          layout='constrained'
          width='320'
          className='my-3'
        />
        <StaticImage
          src='../images/taller/taller-3.jpg'
          alt='Taller MECI'
          layout='constrained'
          width='320'
          className='my-3'
        />
        <StaticImage
          src='../images/taller/taller-4.jpg'
          alt='Taller MECI'
          layout='constrained'
          width='320'
          className='my-3'
        />
        <StaticImage
          src='../images/taller/taller-5.jpg'
          alt='Taller MECI'
          layout='constrained'
          width='320'
          className='my-3'
        />
        <StaticImage
          src='../images/taller/taller-6.jpg'
          alt='Taller MECI'
          layout='constrained'
          width='320'
          className='my-3'
        />
        <StaticImage
          src='../images/taller/taller-7.jpg'
          alt='Taller MECI'
          layout='constrained'
          width='320'
          className='my-3'
        />
        <StaticImage
          src='../images/taller/taller-8.jpg'
          alt='Taller MECI'
          layout='constrained'
          width='320'
          className='my-3'
        />
        <StaticImage
          src='../images/taller/taller-9.jpg'
          alt='Taller MECI'
          layout='constrained'
          width='320'
          className='my-3'
        />
        <StaticImage
          src='../images/taller/taller-10.jpg'
          alt='Taller MECI'
          layout='constrained'
          width='320'
          className='my-3'
        />
        <StaticImage
          src='../images/taller/taller-11.jpg'
          alt='Taller MECI'
          layout='constrained'
          width='320'
          className='my-3'
        />
        <StaticImage
          src='../images/taller/taller-12.jpg'
          alt='Taller MECI'
          layout='constrained'
          width='320'
          className='my-3'
        />
      </div>
      <ContactForm />
    </Layout>
  )
}

export default ElTaller
